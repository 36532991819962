<form [formGroup]="form">
  <div class="details-traveler">
    <div class="formTitle">
      <div class="d-flex flex-column justify-content-between">
        <img class="width-fill" src="assets/images/haya/rotary.jpg">
        <h4 class="donation-header">Donation</h4>
      </div>
    </div>
    <div class="col-12 d-flex overflow-auto" id="style-15">
      <div class="row">
        <div class="col-12">
          <mat-form-field appearance="fill">
            <mat-label class="star">Full Name</mat-label>
            <input matInput placeholder="John" type="text" formControlName="fullName">
            <div class="mat-error error-age" *ngIf="form.get('fullName').errors?.required && form.get('fullName').touched">fullName is Required</div>
            <div class="mat-error error-age" *ngIf="form.get('fullName').errors?.pattern && form.get('fullName').touched">Please Type Only Letters, At Least 3 Letters</div>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field appearance="fill">
            <mat-label class="star">Email</mat-label>
            <input matInput placeholder="examle@gmail.com" type="text" formControlName="email" (focusout)="checkMail()">
            <div class="mat-error error-age " *ngIf="form.get('email').errors?.someProp">{{form.get('email').errors?.someProp}}</div>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field appearance="fill">
            <mat-label class="star">Phone Number</mat-label>
            <input matInput placeholder="Phone Number" type="text" formControlName="phone">
            <div  class="mat-error error-age" *ngIf="form.get('phone').errors?.pattern" >Please Type Numbers Only.</div>
            <div class="mat-error error-age" *ngIf="form.get('phone').errors?.required && form.get('phone').touched">phone is Required</div>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field appearance="fill">
            <mat-label class="star">Amount</mat-label>
            <input matInput placeholder="amount" type="text" formControlName="amount">
            <div  class="mat-error error-age" *ngIf="form.get('amount').errors?.pattern" >Please Type Numbers Only.</div>
          </mat-form-field>
        </div>
        <div class="col-12">
          <span class="font-weight-bold d-block mb-2">When Do You Expect To Send Your Check ?</span>
          <mat-form-field appearance="fill">
            <mat-label>Schedule</mat-label>
            <mat-select  [(ngModel)]="selected" formControlName="schedule">
              <mat-option [value]="'A week'">
                A week
              </mat-option>
              <mat-option [value]="'Ten Days'">
                Ten Days
              </mat-option>
              <mat-option [value]="'Two Weeks'">
                Two Weeks
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field appearance="fill">
            <mat-label class="star">Pledge Date</mat-label>
            <input matInput id="pledge_date"  placeholder="pledge Date" readonly formControlName="pledge_date">
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field appearance="fill" (click)="due_date.open()">
            <mat-label class="star">Due Date</mat-label>
            <input matInput placeholder="Due Date" (dateChange)="checkDate($event)" [matDatepicker]="due_date" formControlName="due_date">
            <mat-datepicker-toggle matSuffix [for]="due_date"></mat-datepicker-toggle>
            <mat-datepicker #due_date></mat-datepicker>
            <div class="mat-error error-age" *ngIf="form.get('due_date').errors?.someProp">{{form.get('due_date').errors?.someProp}}</div>
            <div class="mat-error error-age" *ngIf="form.get('due_date').errors?.required && form.get('due_date').errors?.touched">Please enter a Due Date</div>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field appearance="fill">
            <mat-label class="star">Purpose</mat-label>
            <input matInput placeholder="Purpose" [matAutocomplete]="purpose_id" type="text"
                   formControlName="purpose_id">
            <mat-autocomplete autoActiveFirstOption #purpose_id="matAutocomplete">
              <mat-option *ngFor="let option of purpose" [value]="option.name">
                {{option.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

<!--        <div class="col-12">-->
<!--          <div class="item d-flex">-->
<!--            <button>upload check picture-->
<!--              <i class="fas fa-image"></i>-->
<!--            </button>-->
<!--            <input id="pic" type="file" #selectFile formControlName="image"-->
<!--                   (change)="onFileSelected($event.target.files)" accept="image/*">-->
<!--            <div class="image-cheque" (click)="selectFile.click()"><img alt="" id="blah"></div>-->
<!--          </div>-->
<!--        </div>-->
        <div class="col-12 text-center">
          <button class="btn submit-btn" [disabled]="form.status == 'INVALID'" (click)="close(); rotaryApi();">SAVE</button>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-center align-items-center">
    <span>Powered By </span>
    <img width="60" src="assets/images/haya/NEW%20EGYPT%20LOGO%20(1).png">
    <b>NEW EGYPT GROUP</b>
  </div>
</form>


