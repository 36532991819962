<div class="container">
  <div class="projects">
  <h1 class="  header">
    DONATE CHILDREN
  </h1>
    <owl-carousel-o [options]="carouselOptions">
      <ng-container>
        <ng-container>
          <ng-template carouselSlide>
            <div class="p-2">
              <div class="project" (click)="projectDetails()">
              <img src="../../../../assets/images/Image.png">
              <div class="project-details">
              <p class="project-name">Ahmed Mostafa</p>
              <div class="location"><img src="../../../../assets/images/Icon%20feather-map-pin.svg"> Western City - Saft Trab</div>
                <div class="article">Age : 10 Years</div>
                <div class="article">Health Status : healthy</div>
                <div class="article">Family Income : 400 EG</div>
              </div>
            </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="p-2">
              <div class="project" (click)="projectDetails()">
                <img src="../../../../assets/images/Image.png">
                <div class="project-details">
                  <p class="project-name">Ahmed Mostafa</p>
                  <div class="location"><img src="../../../../assets/images/Icon%20feather-map-pin.svg"> Western City - Saft Trab</div>
                  <div class="article">Age : 10 Years</div>
                  <div class="article">Health Status : healthy</div>
                  <div class="article">Family Income : 400 EG</div>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="p-2">
              <div class="project" (click)="projectDetails()">
                <img src="../../../../assets/images/Image.png">
                <div class="project-details">
                  <p class="project-name">Ahmed Mostafa</p>
                  <div class="location"><img src="../../../../assets/images/Icon%20feather-map-pin.svg"> Western City - Saft Trab</div>
                  <div class="article">Age : 10 Years</div>
                  <div class="article">Health Status : healthy</div>
                  <div class="article">Family Income : 400 EG</div>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="p-2">
              <div class="project" (click)="projectDetails()">
                <img src="../../../../assets/images/Image.png">
                <div class="project-details">
                  <p class="project-name">Ahmed Mostafa</p>
                  <div class="location"><img src="../../../../assets/images/Icon%20feather-map-pin.svg"> Western City - Saft Trab</div>
                  <div class="article">Age : 10 Years</div>
                  <div class="article">Health Status : healthy</div>
                  <div class="article">Family Income : 400 EG</div>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="p-2">
              <div class="project" (click)="projectDetails()">
                <img src="../../../../assets/images/Image.png">
                <div class="project-details">
                  <p class="project-name">Ahmed Mostafa</p>
                  <div class="location"><img src="../../../../assets/images/Icon%20feather-map-pin.svg"> Western City - Saft Trab</div>
                  <div class="article">Age : 10 Years</div>
                  <div class="article">Health Status : healthy</div>
                  <div class="article">Family Income : 400 EG</div>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="p-2">
              <div class="project" (click)="projectDetails()">
                <img src="../../../../assets/images/Image.png">
                <div class="project-details">
                  <p class="project-name">Ahmed Mostafa</p>
                  <div class="location"><img src="../../../../assets/images/Icon%20feather-map-pin.svg"> Western City - Saft Trab</div>
                  <div class="article">Age : 10 Years</div>
                  <div class="article">Health Status : healthy</div>
                  <div class="article">Family Income : 400 EG</div>
                </div>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </ng-container>
    </owl-carousel-o>
  <div class="d-flex justify-content-center">
    <a style="text-decoration: none" routerLink="/children">
      <button class="projects-btn">ALL CASES</button>
    </a>
  </div>
</div>
</div>
