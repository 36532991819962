<app-header></app-header>
<div style="background-color: #F8F7F5 ">
  <div class="container">
  <nav>
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a [routerLink]="['/']"></a></li>
      <li class="breadcrumb-item"><a [routerLink]="['/projects']">المشاريع</a></li>
      <li class="breadcrumb-item active">{{project?.name_ar}}</li>
    </ol>
  </nav>
  </div>
<div class="container project">
  <div class="head">
    <div>
      <h1 class="project-name">{{project?.name_ar}}</h1>
      <div class="location"><img src="assets/images/Icon%20feather-map-pin.svg"> Western City - Saft Trab</div>
    </div>
    <div>
      <button class="projects-btn">DONATE</button>
    </div>
  </div>
  <div class="col-12 d-flex justify-content-between">
    <div class="col-6">
      <video class="img-fluid home-video"
             title="EGYPT"
             controls muted="" playsinline="" loop="" onloadedmetadata="this.muted = true"
             src="{{videoUrl}}">
      </video>
    </div>
    <div class="col-6">
      <div class="photos">
        <img style="height: 120px; margin-bottom: 27px; border-radius: 8px; max-width: 200px" *ngFor="let item of items; index as i"
             [lightbox]="i"
             [src]="item.data.thumb">
      </div>
    </div>
  </div>

<!--  <div class="img-section">-->
<!--    <img class="img" src="assets/images/Image.jpg">-->
<!--    <img class="img" src="assets/images/Image.jpg">-->
<!--  </div>-->
  <div class="d-flex flex-row justify-content-between align-items-baseline mt-3">
    <h2 class="project-name">{{project?.name_ar}}</h2>
    <div class="price">{{project?.budget | currency}}</div>
  </div>
  <div class="article">{{project?.description_ar}}</div>
  <a class="see-more" *ngIf="project?.description_ar.length > 200">See More</a>
  <div class="duration">
    <div><img  src="assets/images/Time%20Icon.svg"> تاريخ البدء : {{transform(project?.start_date)}}</div>
    <div><img  src="assets/images/Time%20Icon.svg"> تاربخ الانتهاء : {{transform(project?.end_date)}}</div>
  </div>
</div>
</div>
<app-footer></app-footer>
