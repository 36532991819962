<div class="footer">
  <div class="row">
    <div class="contain-link three">
      <div class="logo mr-3">
        <img src="assets/images/haya/HAYA%20KARIMA%20FOUNDATION.png ">
      </div>
    </div>
    <div class="contain-link three">
      <p><span>Contact us at</span>  <a href="mailto:contact@hayakarima.com" class="d-inline"> : contact@hayakarima.com </a></p>
      <a>© 2019 All rights reserved.</a>
    </div>
    <div class="contain-link">
      <a href="https://hayakarima.com/principles.html">Basic Principles</a>
      <a href="https://hayakarima.com/target.html">Target Groups</a>
      <a href="https://hayakarima.com/inputs.html">Initiative Interventions</a>
      <a href="https://hayakarima.com/phases.html">Stages of Initiative's Work</a>
    </div>
    <div class="contain-link">
      <a href="https://hayakarima.com/index.html">Home</a>
      <a href="https://hayakarima.com/about.html">About Haya Karima</a>
      <a href="https://hayakarima.com/goals.html">Initiative Objectives</a>
      <a href="https://hayakarima.com/anchors.html">Initiative Pillars</a>
    </div>
  </div>
</div>
