import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApisService} from '../../services/apis.service';
import {AppComponent} from '../../app.component';
import {SeoService} from '../../services/seo.service';
import { MetaService } from '@ngx-meta/core';
@Component({
  selector: 'app-video-description',
  templateUrl: './video-description.component.html',
  styleUrls: ['./video-description.component.scss']
})
export class VideoDescriptionComponent implements OnInit {
  videos: any;
  id: any;
  video: any;
  title: any;
  description: any;
  date: any;
  latestNews: any;
  poster: any;
  totalDonation: any;
  hostUrl;
  routerUrl;
  amount;
  donorsNames;
  constructor(private route: ActivatedRoute, private apis: ApisService, private router: Router, private seoService: SeoService) {
  }

  ngOnInit(): void {
    const hostUrl = AppComponent.hostUrl;
    this.hostUrl = hostUrl;
    this.routerUrl = this.router.url;
    this.route.params.subscribe(params => {
      this.id = +params['id'];
    });
    this.apis.videosApi().subscribe((response: any) => {
      console.log(response);
      this.videos = response.data;
      for (const video of this.videos) {
        if (this.id === video['id']) {
          this.title = video['title'];
          this.description = video['description'];
          this.poster = video['thumb'];
          this.date = video['created_at'];
          this.video = video['video_url'];
          this.seoService.updateTitle(video['title']);
          this.seoService.updateDescription(video['description']);
        }
      }
    }, err => {
      console.log(err);
    });
    this.getNews();
    this.seoUpdate();
    this.getDonation();
    window.scrollTo(0 ,0);
  }
  getDonation(): any{
    this.apis.getDonation().subscribe((res: any) => {
      this.amount = res.data.credit_dontaion;
      this.donorsNames = res.data.donatives;
    }, err => {
      console.log(err);
    });
  }

  newsDetails(id): void {
    this.router.navigate(['/news-details/' + id]);
  }

  getNews(): void {
    this.apis.newsApi().subscribe((data: any) => {
      this.latestNews = data.data;

    }, (err: any) => {
    });
  }

  seoUpdate(): any {
    this.seoService.updateConstMetaTags();
    this.seoService.updateImage(this.poster);
    this.seoService.updateUrl();

  }

  transform(n: string): string {
    var date = new Date(n);
    var months = ['يناير', 'فبراير', 'مارس', 'إبريل', 'مايو', 'يونيو',
      'يوليو', 'أغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر'
    ];
    var days = ['اﻷحد', 'اﻷثنين', 'الثلاثاء', 'اﻷربعاء', 'الخميس', 'الجمعة', 'السبت'];
    var delDateString = days[date.getDay()] + ', ' + date.getDate() + ' ' + months[date.getMonth()] + ', ' + date.getFullYear();
    return delDateString;
  }

  prevent(e): void {
    e.preventDefault();
  }
  numberWithCommas(x): any {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
