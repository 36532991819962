<div class="container">
  <div class="projects">
  <h1 class="  header">
    أهم المشاريع
  </h1>
    <owl-carousel-o [options]="carouselOptions">
        <ng-container>
          <ng-template carouselSlide *ngFor="let project of projects">
            <div class="p-2">
              <div class="project" (click)="projectDetails(project.id)">
              <div *ngIf="project.project_media[0].url_type == 0" class="head-img">{{project.budget | currency}}</div>
              <video *ngIf="project.project_media[0].url_type == 1" controls src="{{project.project_media[0].url}}"></video>
                <img style="height: 177px" *ngIf="project.project_media[0].url_type == 0" src="{{project.project_media[0].url}}">
              <div class="project-details">
              <p class="project-name">{{project.name_ar}}</p>
              <div class="location"><img src="../../../../assets/images/Icon%20feather-map-pin.svg"> الغربية - صفط تراب</div>
              <span class="article">{{project.description_ar.substring(0,200)}}</span>
                <hr class="hr">
                <div class="d-flex justify-content-between" style="direction: rtl">
                  <div class="duration"><img class="ml-1"  src="../../../../assets/images/Time%20Icon.svg"> تاريخ البدء :</div>
                  <div class="text-right">{{transform(project.start_date)}}</div>
                </div>
                <div class="d-flex justify-content-between mb-2" style="direction: rtl">
                  <div class="duration"><img class="ml-1"  src="../../../../assets/images/Time%20Icon.svg"> تاريخ الانتهاء :</div>
                  <div class="text-right">{{transform(project.end_date)}}</div>
                </div>
              </div>
            </div>
            </div>
          </ng-template>
        </ng-container>
    </owl-carousel-o>
  <div class="d-flex justify-content-center">
    <a style="text-decoration: none" routerLink="/projects">
      <button class="projects-btn">جميع المشاريع</button>
    </a>
  </div>
</div>
</div>
