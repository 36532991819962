import {Component, Injectable, OnInit} from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {ApisService} from '../../../services/apis.service';
import {Router} from '@angular/router';
import {SeoService} from '../../../services/seo.service';
import {AppComponent} from '../../../app.component';
import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Injectable()
@Component({
  selector: 'app-home-videos-section',
  templateUrl: './home-videos-section.component.html',
  styleUrls: ['./home-videos-section.component.scss']
})
export class HomeVideosSectionComponent implements OnInit, PipeTransform {
  videoUrl = 'https://player.vimeo.com/external/555380977.hd.mp4?s=e20422d7b72b89b39f9d245a8082be81cc3f5a2a&profile_id=175&download=1';
  readMore = true;
  carouselOptions: OwlOptions = {
    navText: ['<i class=\'arrow arrowNext fa fa-chevron-left\'></i>', '<i class=\'arrow arrowPrev fa fa-chevron-right\'></i>'],
    responsive: {
      0: {items: 1},
      768: {items: 2},
      992: {items: 3}
    },
    items: 3,
    loop: true,
    autoplay: true,
    dots: false,
    nav: true
  };
  videos: any;
  imageObject = [{
    video: 'https://www.youtube.com/embed/E5GQW8ivGS8',
    title: 'Youtube example one with title.',
    alt: 'youtube video',
    id: 4
  }, {
    video: 'https://youtu.be/6pxRHBw-k8M',
    alt: 'youtube video',
    id: 3
  }, {
    video: 'https://sanjayv.github.io/ng-image-slider/contents/assets/video/movie2.mp4',
    posterImage: 'https://slotuniverses.co.uk/wp-content/uploads/sites/12030/upload_fed1091b34dcf8203c0729c4faa62315.png',
    title: 'Youtube example one with title.',
    id: 5
  }];


  constructor(
    private apis: ApisService,
    private router: Router,
    private seoService: SeoService,
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit(): void {
    this.getVideos();
  }

  getVideo(e): any {
    console.log(e);
  }

  getVideos(): void {
    this.apis.videosApi().subscribe((response: any) => {
      this.videos = response.data;
    }, err => {
      console.log(err);
    });
  }

  transform(n: string): string {
    var date = new Date(n);
    var months = ['يناير', 'فبراير', 'مارس', 'إبريل', 'مايو', 'يونيو',
      'يوليو', 'أغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر'
    ];
    var days = ['اﻷحد', 'اﻷثنين', 'الثلاثاء', 'اﻷربعاء', 'الخميس', 'الجمعة', 'السبت'];
    var delDateString = days[date.getDay()] + ', ' + date.getDate() + ' ' + months[date.getMonth()] + ', ' + date.getFullYear();
    return delDateString;
  }

  videDetails(id): void {
    this.router.navigate(['videos-description', id]);
  }

  prevent(e): void {
    e.preventDefault();
  }
}
