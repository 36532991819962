<app-header></app-header>
<img style="width: 100%" src="assets/images/gray-plane-wing-2.png">
<h1 class="title">Donate Children</h1>
<div class="projects">
  <div class="container">
    <div class="project-cards">
      <div class="project" (click)="caseDetails()">
        <img src="../../../assets/images/Image.png">
        <div class="project-details">
          <p class="project-name">Ahmed Mostafa</p>
          <div class="location"><img src="assets/images/Icon%20feather-map-pin.svg"> Western City - Saft Trab</div>
          <div class="article">Age : 10 Years</div>
          <div class="article">Health Status : healthy</div>
          <div class="article">Family Income : 400 EG</div>
        </div>
      </div>
      <div class="project" (click)="caseDetails()">
        <img src="../../../assets/images/Image.png">
        <div class="project-details">
          <p class="project-name">Ahmed Mostafa</p>
          <div class="location"><img src="assets/images/Icon%20feather-map-pin.svg"> Western City - Saft Trab</div>
          <div class="article">Age : 10 Years</div>
          <div class="article">Health Status : healthy</div>
          <div class="article">Family Income : 400 EG</div>
        </div>
      </div>
      <div class="project" (click)="caseDetails()">
        <img src="../../../assets/images/Image.png">
        <div class="project-details">
          <p class="project-name">Ahmed Mostafa</p>
          <div class="location"><img src="assets/images/Icon%20feather-map-pin.svg"> Western City - Saft Trab</div>
          <div class="article">Age : 10 Years</div>
          <div class="article">Health Status : healthy</div>
          <div class="article">Family Income : 400 EG</div>
        </div>
      </div>
      <div class="project" (click)="caseDetails()">
        <img src="../../../assets/images/Image.png">
        <div class="project-details">
          <p class="project-name">Ahmed Mostafa</p>
          <div class="location"><img src="assets/images/Icon%20feather-map-pin.svg"> Western City - Saft Trab</div>
          <div class="article">Age : 10 Years</div>
          <div class="article">Health Status : healthy</div>
          <div class="article">Family Income : 400 EG</div>
        </div>
      </div>
      <div class="project" (click)="caseDetails()">
        <img src="../../../assets/images/Image.png">
        <div class="project-details">
          <p class="project-name">Ahmed Mostafa</p>
          <div class="location"><img src="assets/images/Icon%20feather-map-pin.svg"> Western City - Saft Trab</div>
          <div class="article">Age : 10 Years</div>
          <div class="article">Health Status : healthy</div>
          <div class="article">Family Income : 400 EG</div>
        </div>
      </div>
      <div class="project" (click)="caseDetails()">
        <img src="../../../assets/images/Image.png">
        <div class="project-details">
          <p class="project-name">Ahmed Mostafa</p>
          <div class="location"><img src="assets/images/Icon%20feather-map-pin.svg"> Western City - Saft Trab</div>
          <div class="article">Age : 10 Years</div>
          <div class="article">Health Status : healthy</div>
          <div class="article">Family Income : 400 EG</div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
