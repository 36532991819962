import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {HayahComponent} from '../../pages/hayah/hayah.component';

@Component({
  selector: 'app-president-document',
  templateUrl: './president-document.component.html',
  styleUrls: ['./president-document.component.scss']
})
export class PresidentDocumentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}
