import { Component, OnInit } from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {Router} from '@angular/router';

@Component({
  selector: 'app-donate',
  templateUrl: './donate.component.html',
  styleUrls: ['./donate.component.scss']
})
export class DonateComponent implements OnInit {
  carouselOptions2: OwlOptions = {
    navText: ['<i class=\'arrow arrowNext fa fa-chevron-left\'></i>', '<i class=\'arrow arrowPrev fa fa-chevron-right\'></i>'],
    responsive: {
      0: {items: 1},
      768: {items: 2},
      992: {items: 3}
    },
    items: 3,
    nav: true,
    loop: false,
    autoplay: true,
    autoplayTimeout: 5000,
    dots: false,
    autoplayHoverPause: true
  };
  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  projectDetails(): void{
    this.router.navigate(['/project-details']);
  }
}
