import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-mission',
  templateUrl: './our-mission.component.html',
  styleUrls: ['./our-mission.component.scss']
})
export class OurMissionComponent implements OnInit {
  videoUrl3 = 'https://player.vimeo.com/external/572639140.hd.mp4?s=55094e469bf5a7aa09329aab28dfde213e1dfd1c&profile_id=175';

  constructor() { }

  ngOnInit(): void {
  }
  prevent(e): void{
    e.preventDefault();
  }
}
