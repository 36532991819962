import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {VideoDescriptionComponent} from './pages/video-description/video-description.component';
import {NewsDetailsComponent} from './pages/news-details/news-details.component';
import {HayahComponent} from './pages/hayah/hayah.component';
import {BrowserModule} from '@angular/platform-browser';
import {EventComponent} from './pages/event/event.component';
import {TestComponent} from './pages/test/test.component';
import {HomeComponent} from "./pages/home/home.component";
import {DonateFormComponent} from "./components/donate-form/donate-form.component";

const routes: Routes = [
  {path: '', component: HayahComponent},
  // {path: 'videos-description/:id', component: VideoDescriptionComponent},
  {path: 'news-details/:id', component: NewsDetailsComponent},
  {path: 'events', component: EventComponent},
  // {path: 'test', component: TestComponent},
];

@NgModule({
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled'
    })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
