<h1 class="header">رؤيتنا</h1>
<div class="our-vision">
  <div class="container">
    <div class="row">
      <div class="col-8">
        <div class="vision">
          <h2 class="text-right">رؤيتنا</h2>
          <p>الريادة و التميز في دعم عمليات التنمية المستدامة و دعم أخواتنا و أهلنا من الغير قادرين لمواجهة صعوبات
            الحياه .
          </p>
          <button class="projects-btn">معرفة المزيد</button>
        </div>
      </div>
    </div>
  </div>
</div>
