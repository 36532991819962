<app-header></app-header>
<section class="bg-F8F8F8">
  <div class="head-line-image">
    <h3>Video Details</h3>
  </div>
  <div class="container-fluid">
    <div class="country-lists">
      <div class="row">
        <div class="col-md-9 col-sm-12 text-end news-description">
          <div id="news">
            <div class="h2">
              {{title}}
            </div>
            <div class="d-flex mb-2 flex-row-reverse justify-content-between">
              <div class="list-headline location">
                <span class="ml-2">{{transform(date | date: 'yyyy , MM , dd')}}</span>
                <span>
                  <span>{{date | date: 'hh:mm'}}</span>
                </span>
                <span class="pr-1">صباحا</span>
              </div>
              <a class="icon d-none" href="https://www.facebook.com/sharer/sharer.php?u={{hostUrl}}{{routerUrl}}">
                <button class="share btn">
                  مشاركة
                  <i class="fa fa-facebook"></i>
                </button>
              </a>
            </div>
            <!--            <video class="img-fluid home-video"-->
            <!--                   title="EGYPT"-->
            <!--                   muted="" playsinline="" loop="" onloadedmetadata="this.muted = true"-->
            <!--                   controls-->
            <!--                   poster="{{poster}}"-->
            <!--                   src="{{video}}" (contextmenu)="prevent($event)">-->
            <!--            </video>-->
            <iframe  width="100%" height="576px" [src]="video | safe: 'resourceUrl'">   </iframe>
            <article class="text-left direction-ltr important-article mt-4">All donations made by those living in usa are 100% tax deductible, and 100% of donations go directly to the project with no administrative costs.</article>
            <div class="h3">
              <span>{{title}}</span>
            </div>
            <div class="content-news">
              {{description}}
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-12 small-screen">
          <div class="sidebar">
            <div class="latest-news">
              <div class="items">
                <div>
                  <div class="inner-content">
                    <div class="image-news">
                      <div class="text-center  btn-donate-sec mt-4 mb-4">
                        <button class="aplos-donation-button button2c btn donateBtn"
                                data-widget-id="97EF7E128ABE7D1557C67221F2A6B9BC">
                          Donate via Debit/Credit Card
                          Bank Account(ACH)
                        </button>
                      </div>
                      <hr class="w-100 mt-2">
                      <div id="donation-section" class="large-sc">
                        <div id="donation" class="donation">
                          <span>Our Target</span>
                          <br>
                          <span class="target-donation">$1,000,000</span>
                          <br>
                          Donations Earned <br>
                          <span class="today">Today</span>
                          <br>
                          <span class="price"><app-donate-price></app-donate-price></span>
                        </div>
                      </div>
                    </div>
                    <p></p>
                  </div>
                  <div class="date-news">
                  </div>
                  <hr>
                </div>
                <div class="header-news">
                  <span>Target Groups</span>
                </div>
                <div class="item">
                  <div class="row">
                    <ul class="direction-ltr">
                      <li>Volunteers</li>
                      <li>The families most in <br> need in rural communities.</li>
                      <li>Young people who are <br> capable to work.</li>
                      <li>Orphans and children.</li>
                      <li>The elderly.</li>
                      <li>Orphans and children.</li>
                      <li>People with special needs.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer-hayah></app-footer-hayah>
