import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApisService} from '../../services/apis.service';
import {AppComponent} from '../../app.component';
import {SeoService} from '../../services/seo.service';
import {ActivatedRoute, Router} from '@angular/router';
import {of, Subscription, timer} from 'rxjs';
import {catchError, filter, switchMap} from 'rxjs/operators';
import {RotaryFormComponent} from '../../components/rotary-form/rotary-form.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit, OnDestroy {
  events: any;
  error: any;
  show1: any = false;
  show2: any = false;
  show3: any = false;
  show4: any = false;
  hostUrl: any;
  routerUrl: any;
  donorsNames: any = [];
  creditAmount;
  checkAmount: any;
  totalAmount: any;
  test: any;
  minutes: number;
  subscription: Subscription;
  intervalPeriod: number = 1;
  data: any;
  amount;
  donation;
  panelOpenState3 = false;
  dateToday: any = new Date();

  constructor(private apis: ApisService, private seoService: SeoService, private router: Router, private route: ActivatedRoute, public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    console.log(this.dateToday);
    this.routerUrl = this.router.url;
    const hostUrl = AppComponent.hostUrl;
    this.hostUrl = hostUrl;
    this.getEvent('2021-10-2');
    this.seoUpdate();
    this.getDonation();
  }

  ngOnDestroy(): void {
  }

  dateFormat(dateIn): any {
    let date = new Date(dateIn);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = +'0' + dt;
    }
    if (month < 10) {
      month = +'0' + month;
    }
    const dateSelected = year + '-' + month + '-' + dt;
    this.getEvent(dateSelected);
  }

  getDonation(): any {
    this.apis.getDonation().subscribe(data => {
      this.data = data;
      console.log(this.data);
      this.amount = this.data?.data.credit_dontaion;
      this.donation = this.data?.data;
      this.donorsNames = this.data?.data.donatives;
      this.checkAmount = this.data.data.check_donation;
      this.totalAmount = this.data.data.total_amount;
      this.creditAmount = this.data.data.credit_dontaion;
      this.donorsNames = this.data.data.donatives;
    });
  }

  getEvent(date): any {
    this.apis.eventDateApi(date).subscribe((res: any) => {
      if (res.data.events) {
        this.events = res.data.events;
        this.error = '';
      } else if (res.data.msg) {
        this.error = res.data.msg;
      }
    }, err => {
      console.log(err);
    });
  }

  seoUpdateFace(): any {
    this.seoService.updateConstMetaTags();
    this.seoService.updateTitle('حملة تبرعات من المصريين بالولايات المتحدة لدعم "حياة كريمة " لأهالينا');
    this.seoService.updateDescription('كانت نقطة الانطلاق من أحد ايقونات "مصر تستطيع بالتاء المربوطة" والمتصدرة قائمة «فوربس» كأحد أفضل مستشاري\n' + '            إدارة الثروات بالولايات المتحدة الأمريكية، السيدة "ليلي بنس "و زوجها رجل الأعمال الأمريكي درايدن بنس، والذين\n' +
      '            استجابوا لدعوة وزيرة الهجرة وشئون المصريين بالخارج السفيرة نبيلة مكرم لدعم أنشطة المشروع الرئاسي القومي الذي\n' +
      '            تشرف عليه مؤسسة «حياة كريمة» عبر الاتفاق علي تنظيم فاعلية لجمع تبرعات المصريين بالولايات المتحدة للمساهمة في\n' +
      '            دعم المبادرة ،كما تم دعوة مؤسسة "نيوايحيبت " للاشتراك في تنظيم وادارة الفاعلية ومرة جديدة يؤكدون انهم نموذج\n' +
      '            للمصريين بالخارج الذين لا يدخروا جهدًا في مساندة وطنهم ودعم أشقائهم فيه، ومستعدون لبذل كل الجهود الممكنة\n' +
      '            للمساهمة في دعم المناطق الأكثر احتياجًا في مصر وإشراك المصريين بالولايات المتحدة وحثهم على ذلك ');
    this.seoService.updateImage(AppComponent.hostUrl + '/assets/images/haya/bg-home.jpg');
    // location.replace ('https://www.facebook.com/sharer/sharer.php?u=' + this.hostUrl + this.routerUrl);
    window.location.href = 'https://www.facebook.com/sharer/sharer.php?u=' + this.hostUrl + this.routerUrl;
  }

  numberWithCommas(x): any {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  openDialog(): any {
    const dialogRef = this.dialog.open(RotaryFormComponent, {
      maxHeight: '100vh',
      maxWidth: '40vw',
      data: {
        animal: 'panda'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  seoUpdate(): any {
    this.seoService.updateConstMetaTags();
    this.seoService.updateTitle('حملة تبرعات من المصريين بالولايات المتحدة لدعم "حياة كريمة " لأهالينا');
    this.seoService.updateDescription('كانت نقطة الانطلاق من أحد ايقونات "مصر تستطيع بالتاء المربوطة" والمتصدرة قائمة «فوربس» كأحد أفضل مستشاري\n' +
      '            إدارة الثروات بالولايات المتحدة الأمريكية، السيدة "ليلي بنس "و زوجها رجل الأعمال الأمريكي درايدن بنس، والذين\n' +
      '            استجابوا لدعوة وزيرة الهجرة وشئون المصريين بالخارج السفيرة نبيلة مكرم لدعم أنشطة المشروع الرئاسي القومي الذي\n' +
      '            تشرف عليه مؤسسة «حياة كريمة» عبر الاتفاق علي تنظيم فاعلية لجمع تبرعات المصريين بالولايات المتحدة للمساهمة في\n' +
      '            دعم المبادرة ،كما تم دعوة مؤسسة "نيوايحيبت " للاشتراك في تنظيم وادارة الفاعلية ومرة جديدة يؤكدون انهم نموذج\n' +
      '            للمصريين بالخارج الذين لا يدخروا جهدًا في مساندة وطنهم ودعم أشقائهم فيه، ومستعدون لبذل كل الجهود الممكنة\n' +
      '            للمساهمة في دعم المناطق الأكثر احتياجًا في مصر وإشراك المصريين بالولايات المتحدة وحثهم على ذلك ');
    this.seoService.updateImage(AppComponent.hostUrl + '/assets/images/haya/bg-home.jpg');
    // location.replace ('https://www.facebook.com/sharer/sharer.php?u=' + this.hostUrl + this.routerUrl);
    // window.location.href = 'https://www.facebook.com/sharer/sharer.php?u=' + this.hostUrl + this.routerUrl;
  }
}
