import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ApisService} from '../../services/apis.service';
import {HayahComponent} from '../../pages/hayah/hayah.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ConfimDonationComponent} from '../confim-donation/confim-donation.component';


@Component({
  selector: 'app-rotary-form',
  templateUrl: './rotary-form.component.html',
  styleUrls: ['./rotary-form.component.scss']
})
export class RotaryFormComponent implements OnInit {
  form: FormGroup;
  purpose: any;
  fileToUpload: any;
  image: any;
  ImagePath: any;
  message: any;
  selected: any = 'A week';
  date: any;
  constructor(private apis: ApisService, public dialogRef: MatDialogRef<HayahComponent>,  public dialog: MatDialog,) {
  }

  ngOnInit(): void {
    var today = new Date();
    var dateTime = (today.getMonth() + 1) + '-' + today.getDate() + '-' + today.getFullYear();
    this.date = dateTime ;
    this.form = this.initForm();
    this.getDonatePurpose();
    this.form.patchValue({
      purpose_id: 'Haya Karima',
      pledge_date: this.date
    });
  }

  initForm(): FormGroup {
    return new FormGroup({
      fullName: new FormControl('', [Validators.required, Validators.minLength(3), Validators.pattern('[a-zA-Z\\s]*$')]),
      email: new FormControl('', [Validators.minLength(3), Validators.required]),
      // address: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(8)]),
      amount: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      // image: new FormControl('', [Validators.required]),
      pledge_date: new FormControl('', [Validators.required]),
      due_date: new FormControl('', [Validators.required]),
      purpose_id: new FormControl('', [Validators.required]),
      schedule: new FormControl('', [Validators.required]),
    });
  }

  donationForm(): any {
    const formData: FormData = new FormData();
    // formData.append('image', this.fileToUpload);
    formData.append('fullname', this.form.value['fullName']);
    formData.append('email', this.form.value['email']);
    formData.append('phone', this.form.value['phone']);
    // formData.append('address', this.form.value['address']);
    formData.append('pledge_date', this.dateFormat(this.form.value.pledge_date));
    formData.append('due_date', this.dateFormat(this.form.value.due_date));
    formData.append('purpose_id', this.getPurposeObject(this.form.value.purpose_id).id);
    formData.append('schedule', this.form.value['schedule']);
    formData.append('amount', this.form.value['amount']);
    console.log([...formData]);
    return formData;
  }

  getDonatePurpose(): any {
    this.apis.getPurpose().subscribe((data: any) => {
      console.log(data);
        this.purpose = data.data;
      },
      err => {
        console.log(err);
      });
  }

  getPurposeObject(purpose): any {
    const index = this.purpose.findIndex(obj => obj.name === purpose);
    if (index > -1) {
      return this.purpose[index];
    }
  }

  onFileSelected(files: FileList): void {
    this.fileToUpload = files.item(0);
    (<HTMLInputElement> document.getElementById('blah')).src = window.URL.createObjectURL(this.fileToUpload);
    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.image = event.target.result;
        // this.form.patchValue({
        //   image: this.fileToUpload as any
        // });
      };
      reader.readAsDataURL(files[0]);
    }
  }

  close(): void {
    this.dialogRef.close(this.donationForm());
    this.openDialog();
  }

  rotaryApi(): any {
    this.apis.rotaryApi(this.donationForm()).subscribe((res: any) => {
    }, err => {
      console.log(err);
    });
  }
  openDialog(): any {
    const dialogRef = this.dialog.open(ConfimDonationComponent, {
      maxHeight: '67vh',
      maxWidth: '50vw',
      data: this.donationForm(),
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  dateFormat(date): any {
    date = new Date(date);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = +'0' + dt;
    }
    if (month < 10) {
      month = +'0' + month;
    }
    return (year + '-' + month + '-' + dt);
  }
  checkMail(): void {
    this.message = '';
    const mailFormat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (this.form.value.email.match(mailFormat)) {
      this.message = '';
    } else {
      this.message = 'You have entered an invalid email address!';
      this.form.controls['email'].setErrors({'incorrect': true, someProp: 'You have entered an invalid email address'});
    }
  }

  checkDate(e): void{
    this.date = new Date().getTime();
    console.log(this.date);
    var due = new Date(this.dateFormat(e.value)).getTime();
    console.log(due);
    if (this.date >= due) {
      this.form.controls['due_date'].setErrors({'incorrect': true, someProp: 'You must choose a day after today'});
    }
  }
}
