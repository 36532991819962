import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from "../../environments/environment";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class ApisService {
    // domain = 'http://127.0.0.1:8000';
    domain = 'https://staging.backend.hayakarimausa.com/api';
    private donateDomain =environment.apiDonate;
    constructor(private http: HttpClient) {

    }

    videosApi(): any {
        return this.http.get(`${this.domain}/videos`);
    }

    eventDateApi(date): any {
        return this.http.post(`${this.domain}/events-date`, {'date': date});
    }

    getDonateForActivity(id,body):Observable<any> {

        return this.http.post(`${this.donateDomain}/donate-v1.0/` + id, body);
    }
    rotaryApi(data): any {
        const headers = new HttpHeaders().set('Accept', 'application/json');
        return this.http.post(`${this.domain}/rotary-donative`, data, {headers});
    }

    donation(): any {
        return this.http.get(`${this.domain}/donation`);
    }

    getDonation(): any {
        return this.http.get(`${this.domain}/aplos-donations`);
    }

    events(): any {
        return this.http.get(`${this.domain}/events`);
    }

    newsApi(): any {
        const headers = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get(`${this.domain}/news`, {headers});
    }

    projectsApi(): any {
        const headers = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get(`${this.domain}/projects`, {headers});
    }

    getProject(id): any {
        const headers = new HttpHeaders().set('Accept', 'application/json');
        return this.http.post(`${this.domain}/projects/` + id, {headers});
    }

    getNews(id): any {
        const headers = new HttpHeaders().set('Accept', 'application/json');
        return this.http.post(`${this.domain}/public-news/` + id, {headers});
    }

    getPurpose(): any {
        return this.http.get(`${this.domain}/rotary-donative-purpose`);
    }

    getDonate() {
        return this.http.get('https://api.newegyptgroup.com/api/activities/a-donation-campaign-from-egyptians-in-the-united-states-to-support-a-haya-karima-for-our-families');
    }

}
