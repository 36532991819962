<app-header></app-header>
<div class="about-page">

  <!-- start about-header -->
  <div class="about-header">
    <div class="overlay">
      <div class="container">
        <h1 class="text-center">About Us</h1>
      </div>
    </div>
  </div>
  <!-- end about-header -->

  <!-- start about-body -->
  <div class="about-body">

    <div class="mission text-center">
      <div class="container">
        <h2>Our Mission</h2>
        <p>“Bringing the World to You” is FlyAllOver's Vision. We feel that everybody has the right to travel the world
          and experience all the wonderful things life has to offer. </p>
        <img title="flyallover" alt="flyallover" src="./assets/images/about-us/Screenshot%202021-07-07%20140117.png"/>
      </div>
    </div>
    <!-- end mission -->
    <!-- start team -->
    <div class="team text-center">
      <div class="team-header">
        <div class="overlay">
          <div class="container">
            <h2 class="text-center">Our Team</h2>
          </div>
        </div>
      </div>
      <div class="team-body">
        <p></p>
      </div>
    </div>
    <div class="container pb-65">
      <div class="row text-center">
        <p class="m-auto about-team col-6">Our passionate team of travel professionals are ready to serve you taking all your needs into consideration.
          We are a full-service travel agency that specializes in online ticketing and provide recreational and business
          travelers with professional services and consultations. Flyallover is committed to ensuring that your next
          vacation is exactly that... a vacation! So relax, and let us take care of all the details!</p>
      </div>
      <div class="contact-us">
        <form>
          <div class="inside">
            <h2>We aim to reach you anytime and anywhere</h2>
            <!-- Full name -->
            <div class="field">
              <label for="name">*Full
                Name</label>
              <input id="name" type="text"  required/>
            </div>
            <!-- Email -->
            <div class="field">
              <label for="email">*Email Address</label>
              <input id="email" type="email"  required/>
            </div>
            <!-- mobile number -->
            <div class="field">
              <label for="mobile">*Mobile
                Number</label>
              <input id="mobile" type="text"  required/>
            </div>
            <!-- subject -->
            <div class="field">
              <label for="subject">*Subject</label>
              <input id="subject" type="text"  required/>
            </div>
            <!-- message -->
            <div class="field">
              <label for="message">*Your Message</label>
              <textarea id="message" placeholder="Tell us anything you want to.."
                        required></textarea>
            </div>
            <div class="submit">
              <button class="button active">SEND</button>
              <!-- <i *ngIf="roundSpinner" class="fas fa-spinner fa-spin"></i> -->
            </div>
          </div>
        </form>
      </div>

    </div>
    <!-- end team -->
  </div>
  <!-- end about-body -->

</div>
<app-footer></app-footer>
