import { Component, OnInit } from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {Router} from '@angular/router';
import {ApisService} from '../../../services/apis.service';

@Component({
  selector: 'app-recent-news',
  templateUrl: './recent-news.component.html',
  styleUrls: ['./recent-news.component.scss']
})
export class RecentNewsComponent implements OnInit {
  news: any;
  carouselOptions2: OwlOptions = {
    navText: ['<i class=\'arrow arrowNext fa fa-chevron-left\'></i>', '<i class=\'arrow arrowPrev fa fa-chevron-right\'></i>'],
    responsive: {
      0: {items: 1},
      768: {items: 1},
      992: {items: 3}
    },
    items: 1,
    loop: true,
    autoplay: true,
    dots: false,
    nav: true
  };
  constructor(private router: Router, private apis: ApisService){ }

  ngOnInit(): void {
  this.getNews();
  }
  transform(n: string): string {
    var date = new Date(n);
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    var delDateString = days[date.getDay()] + ', ' + date.getDate() + ' ' + months[date.getMonth()] + ', ' + date.getFullYear();
    return delDateString;
  }
  getNews(): void{
    this.apis.newsApi().subscribe((data: any) => {
      this.news = data.data;

    }, (err: any) => {
    });
  }
  newsDetails(id): void{
    this.router.navigate(['/news-details/' + id]);
  }
}
