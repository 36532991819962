import {Component, EventEmitter, Output, Inject, Input, OnInit} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {ApisService} from '../../services/apis.service';


@Component({
  selector: 'app-calender',
  templateUrl: './calender.component.html',
  styleUrls: ['./calender.component.scss']
})

export class CalenderComponent implements OnInit {

  @Output() setDateEvent = new EventEmitter();
  @Input() dateHomeSelected;
  closedDates: any;
  blockedDays: any = [];
  userId = 1;
  date = new Date();
  selectedDate: any;
  days = [
    {
      shortName: 'Mon',
      longName: 'Mon',
      number: '1',
    },
    {
      shortName: 'Tue',
      longName: 'Tue',
      number: '2',
    },
    {
      shortName: 'Wed',
      longName: 'Wed',
      number: '3',
    },
    {
      shortName: 'Thu',
      longName: 'Thu',
      number: '4',
    },
    {
      shortName: 'Fri',
      longName: 'Fri',
      number: '5',
    },
    {
      shortName: 'Sat',
      longName: 'Sat',
      number: '6',
    },
    {
      shortName: 'Sun',
      longName: 'Sun',
      number: '7',
    },
  ];
  months = [
    {
      shortName: 'January',
      longName: 'January',
      number: '1',
      firstDayWeekPosition: '1',
      numberOfDays: [],
      year: this.date.getUTCFullYear(),
      displayedMonth: false,
      fullDate: '',
    },
    {
      shortName: 'February',
      longName: 'February',
      number: '2',
      firstDayWeekPosition: '1',
      numberOfDays: [],
      year: this.date.getUTCFullYear(),
      displayedMonth: false,
      fullDate: '',
    },
    {
      shortName: 'March',
      longName: 'March',
      number: '3',
      firstDayWeekPosition: '1',
      numberOfDays: [],
      year: this.date.getUTCFullYear(),
      displayedMonth: false,
      fullDate: '',
    },
    {
      shortName: 'April',
      longName: 'April',
      number: '4',
      firstDayWeekPosition: '1',
      numberOfDays: [],
      year: this.date.getUTCFullYear(),
      displayedMonth: false,
      fullDate: '',
    },
    {
      shortName: 'May',
      longName: 'May',
      number: '5',
      firstDayWeekPosition: '1',
      numberOfDays: [],
      year: this.date.getUTCFullYear(),
      displayedMonth: false,
      fullDate: '',
    },
    {
      shortName: 'June',
      longName: 'June',
      number: '6',
      firstDayWeekPosition: '1',
      numberOfDays: [],
      year: this.date.getUTCFullYear(),
      displayedMonth: false,
      fullDate: '',
    },
    {
      shortName: 'July',
      longName: 'July',
      number: '7',
      firstDayWeekPosition: '1',
      numberOfDays: [],
      year: this.date.getUTCFullYear(),
      displayedMonth: false,
      fullDate: '',
    },
    {
      shortName: 'August',
      longName: 'August',
      number: '8',
      firstDayWeekPosition: '1',
      numberOfDays: [],
      year: this.date.getUTCFullYear(),
      displayedMonth: false,
      fullDate: '',
    },
    {
      shortName: 'September',
      longName: 'September',
      number: '9',
      firstDayWeekPosition: '1',
      numberOfDays: [],
      year: this.date.getUTCFullYear(),
      displayedMonth: false,
      fullDate: '',
    },
    {
      shortName: 'October',
      longName: 'October',
      number: '10',
      firstDayWeekPosition: '1',
      numberOfDays: [],
      year: this.date.getUTCFullYear(),
      displayedMonth: false,
      fullDate: '',
    },
    {
      shortName: 'November',
      longName: 'November',
      number: '11',
      firstDayWeekPosition: '1',
      numberOfDays: [],
      year: this.date.getUTCFullYear(),
      displayedMonth: false,
      fullDate: '',
    },
    {
      shortName: 'December',
      longName: 'December',
      number: '12',
      firstDayWeekPosition: '1',
      numberOfDays: [],
      year: this.date.getUTCFullYear(),
      displayedMonth: false,
      fullDate: '',
    },
  ];
  displayedMonth = 0;
  selectDayError: any = false;
  closedDatesError: any = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private apis: ApisService,
  ) {
    this.initMonths();
  }

  ngOnInit(): void {
    // this.initClosedDates();
    this.nextMonth();
  }

  // initClosedDates(): any {
  //   this.apis.closedDates().subscribe((response: any) => {
  //     this.closedDates = response;
  //
  //   }, err => {
  //     this.closedDatesError = err;
  //     console.log(err);
  //   });
  // }

  initMonths(): any {
    for (let i = 0; i < this.date.getUTCMonth(); i++) {
      const poppedItem = this.months.shift();
      poppedItem.year = this.date.getUTCFullYear() + 1;
      this.months.push(poppedItem);
    }
    for (const item of this.months) {
      item.firstDayWeekPosition = this.firstDayWeekPosition(item);
      /***/
      item.numberOfDays = Array(new Date(item.year, parseFloat(item.number), 0).getDate());
      /***/
      if ((this.date.getUTCMonth()).toString() === item.number) {
        item.displayedMonth = true;
      }
    }
  }

  convertNumberTo2Digits(numberToConvert: any): any {
    if (numberToConvert < 10) {
      return '0' + numberToConvert;
    }
    return numberToConvert;
  }

  notAllowedDate(month, day): any {
    return (this.date.getDate() + 2) > day + 1 && (this.date.getMonth() + 1) == month.number;
  }

  firstDayWeekPosition(month): any {
    const dateStr = month.longName + ' 1 , ' + month.year + ' GMT-5 ';
    return new Date(dateStr).getUTCDay();
  }

  toArray(numberToConvert): any {
    if (numberToConvert === 0) {

      return Array(6);
    }
    return Array(numberToConvert - 1);
  }

  selectDay(month, day): any {
    this.selectDayError = false;
    if (((this.date.getUTCDate()) > day && this.date.getUTCMonth() === month.number)) {
      console.log('invalid date');
    } else if (this.selectedDate) {
      this.clearDates();
      this.setSelectedDate(month, day);
    } else {
      this.setSelectedDate(month, day);
    }

  }

  setSelectedDate(month, day): any {
    // selectedDate month.number - 1 cuz js months are 0 index
    // selectedDate day + 1 cuz loop (in html) 0 index
    if (!this.selectedDate) {
      const className = '.date-' + month.year + '-' + month.number + '-' + (day + 1);
      document.querySelectorAll(className.toString()).forEach((item) => {
        item.classList.toggle('selectedDay');
      });
      this.selectedDate = new Date(month.year, month.number - 1, day + 1);
      this.setDateEvent.emit(this.selectedDate);
    }
  }

  clearDates(): any {
    this.selectedDate = undefined;
    const dates = this.document.querySelectorAll('.monthDay');
    Array.from(dates).forEach(date => {
      date.classList.remove('selectedDay');
    });
  }

  nextMonth(): any {
    this.displayedMonth++;
  }

  previousMonth(): any {
    this.displayedMonth--;
  }

  isFloat(n): any {
    return Number(n) === n && n % 1 !== 0;
  }

}
