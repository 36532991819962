<div class="bg-white pt-5">
  <div class="container">
    <div class="projects">
      <h1 class="  header">DONATE CHILDREN</h1>
      <owl-carousel-o [options]="carouselOptions2">
        <ng-container>
          <ng-container>
            <ng-template carouselSlide>
              <div class="card-item p-2">
                <div class="project" (click)="projectDetails()">
                  <img src="../../../assets/images/home/Image.png">
                  <div class="project-details">
                    <p class="project-name">احمد مصطفى</p>
                    <div class="location"><img src="assets/images/Icon%20feather-map-pin.svg"> الغربيه - صفط طراب
                    </div>
                    <div class="article d-flex flex-column align-items-start">
                      <p>
                        <span>العمر : </span>
                        <span>10 سنوات</span>

                      </p>
                      <p>
                        <span>الحالة الصحية :</span>
                        <span>جيده</span>
                      </p>
                      <p>
                        <span>دخل الأسرة</span>
                        <span>  400 جنية </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="card-item p-2">
                <div class="project" (click)="projectDetails()">
                  <img src="../../../assets/images/home/Image.png">
                  <div class="project-details">
                    <p class="project-name">احمد مصطفى</p>
                    <div class="location"><img src="assets/images/Icon%20feather-map-pin.svg"> الغربيه - صفط طراب
                    </div>
                    <div class="article d-flex flex-column align-items-start">
                      <p>
                        <span>العمر : </span>
                        <span>10 سنوات</span>
                      </p>
                      <p>
                        <span>الحالة الصحية :</span>
                        <span>جيده</span>
                      </p>
                      <p>
                        <span>دخل الأسرة</span>
                        <span>  400 جنية </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="card-item p-2">
                <div class="project" (click)="projectDetails()">
                  <img src="../../../assets/images/home/Image.png">
                  <div class="project-details">
                    <p class="project-name">احمد مصطفى</p>
                    <div class="location"><img src="assets/images/Icon%20feather-map-pin.svg"> الغربيه - صفط طراب
                    </div>
                    <div class="article d-flex flex-column align-items-start">
                      <p>
                        <span>العمر : </span>
                        <span>10 سنوات</span>
                      </p>
                      <p>
                        <span>الحالة الصحية :</span>
                        <span>جيده</span>
                      </p>
                      <p>
                        <span>دخل الأسرة</span>
                        <span>  400 جنية </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </ng-container>
      </owl-carousel-o>
      <div class="d-flex justify-content-center mt-4">
        <a style="text-decoration: none" routerLink="/projects">
          <button class="projects-btn">كل الحالات</button>
        </a>
      </div>
    </div>
  </div>
</div>
