import { Component, OnInit } from '@angular/core';
import {ApisService} from "../../services/apis.service";

@Component({
  selector: 'app-donate-price',
  templateUrl: './donate-price.component.html',
  styleUrls: ['./donate-price.component.scss']
})
export class DonatePriceComponent implements OnInit {
  donate:any[]=[];
  budget:number;
  expenses:number;
  donatePrice:number;
  constructor(private apis:ApisService) { }

  ngOnInit(): void {
    this.getDonate();
  }
  numberWithCommas(x): any {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  getDonate(){
    this.apis.getDonate().subscribe((data:any)=>{
      this.donate=data.data.activities;
      this.budget=data.data.activities.budget;
      this.expenses=data.data.activities.expenses;
      this.donatePrice= this.budget+this.expenses;
    })
  }
}
