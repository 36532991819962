import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {AppComponent} from '../../app.component';
import {SeoService} from '../../services/seo.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  videoUrl = 'https://player.vimeo.com/external/567641203.hd.mp4?s=c04076dd9a63e7d8dd2c7312cf1178db4066063b&profile_id=175';
  hostUrl;
  constructor(private seoService: SeoService) {
  }

  ngOnInit(): void {
    this.videoHandler();
    const hostUrl = AppComponent.hostUrl;
    this.hostUrl = hostUrl;
    this.seoUpdate();
  }
  seoUpdate(): any {
    this.seoService.updateConstMetaTags();
    this.seoService.updateTitle('Home');
    this.seoService.updateDescription('Home');
    this.seoService.updateImage(AppComponent.hostUrl + '/assets/images/haya/bg-home.jpg');
    // location.replace ('https://www.facebook.com/sharer/sharer.php?u=' + this.hostUrl + this.routerUrl);
    // window.location.href = 'https://www.facebook.com/sharer/sharer.php?u=' + this.hostUrl + this.routerUrl;
  }
  videoHandler(): void {
    if (window.screen.availWidth < 600) {
      this.videoUrl = 'https://player.vimeo.com/external/567151192.hd.mp4?s=db1921b9a7500a7a0cf1846bdaf69cec317782c5&profile_id=175';
    }
  }
  prevent(e): void{
    e.preventDefault();
  }
}
