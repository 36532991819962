import { Component, OnInit } from '@angular/core';
import {SeoService} from '../../services/seo.service';
import {AppComponent} from '../../app.component';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  hostUrl;
  constructor( private seoService: SeoService,) { }
  ngOnInit(): void {
    const hostUrl = AppComponent.hostUrl;
    this.hostUrl = hostUrl;
    this.seoUpdate();
  }
  seoUpdate(): any {
    this.seoService.updateConstMetaTags();
    this.seoService.updateTitle('Events');
    this.seoService.updateDescription('Events');
    this.seoService.updateImage(AppComponent.hostUrl + '/assets/images/haya/bg-home.jpg');
    // location.replace ('https://www.facebook.com/sharer/sharer.php?u=' + this.hostUrl + this.routerUrl);
    // window.location.href = 'https://www.facebook.com/sharer/sharer.php?u=' + this.hostUrl + this.routerUrl;
  }

}
