<app-header></app-header>
<!--<div class="logo">-->
<!--  <img alt="" src="./assets/images/logo.png">-->
<!--</div>-->
<div class="home-video-section">
  <video class="img-fluid home-video"
         title="EGYPT"
         autoplay="" muted="" playsinline="" loop="" onloadedmetadata="this.muted = true"
         oncanplay="this.play();"
         (contextmenu)="prevent($event)"
         src="{{videoUrl}}">
  </video>
  <div class="text-center  btn-donate-sec">
    <button class="aplos-donation-button btn donateBtn" data-widget-id="4E4CF49364BAE9BCB5775A14AC797963">تبرع الان من
      داخل الولايات المتحدة الأمريكية
    </button>
  </div>
</div>
<!--<div class="text-center mt-4 btn-donate-sec">-->
<!--  <button class="aplos-donation-button btn donateBtn" data-widget-id="4E4CF49364BAE9BCB5775A14AC797963">DONATE</button>-->
<!--</div>-->
<!--<app-home-projects-section></app-home-projects-section>-->
<app-our-mission></app-our-mission>
<!--<app-home-children-section></app-home-children-section>-->
<!--<app-our-vision></app-our-vision>-->
  <app-recent-news></app-recent-news>
<div class="container">
  <app-home-videos-section></app-home-videos-section>
</div>
<app-footer></app-footer>
