<div class="videos-section mt-3 mb-5">
  <h3 class="header">Videos</h3>
  <owl-carousel-o [options]="carouselOptions">
    <ng-container>
      <ng-container *ngFor="let video of videos">
        <ng-template carouselSlide>
          <div class="post-container">
            <div class="post">
              <div class="post-photo">
                <iframe width="100%" height="100%" [src]="video['video_url'] | safe: 'resourceUrl'">
                </iframe>
              </div>
              <div class="post-description">
                <div class="offer_name">
                  <h1
                    style="font-size: medium; font-weight: bold; color:#148181">{{video['title'] | slice :0 : 40}}</h1>
                </div>
                <div class="offer_description">
                  <p>{{video['description'] | slice :0 : 200}}
                  </p>
                  <a (click)="videDetails(video['id'])" *ngIf="video['description'].length > 200 && readMore"
                     class="readMore"
                  >Read More..</a>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </ng-container>
  </owl-carousel-o>
</div>

