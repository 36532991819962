import {Component, OnInit} from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {RotaryFormComponent} from '../../rotary-form/rotary-form.component';
import {MatDialog} from '@angular/material/dialog';
import {Router} from "@angular/router";
import {ApisService} from "../../../services/apis.service";
import {DonateFormComponent} from "../../donate-form/donate-form.component";

@Component({
    selector: 'app-videos',
    templateUrl: './videos.component.html',
    styleUrls: ['./videos.component.scss']
})
export class VideosComponent implements OnInit {
    videoUrl: string = '';
    customOptions: OwlOptions = {
        loop: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        autoplay: true,
        autoplayTimeout: 3000,
        dots: false,
        navSpeed: 700,

        responsive: {
            0: {
                items: 1,
            },

            940: {
                items: 1,
            },
        },
    };
    carouselOptionsVideos: OwlOptions = {
        navText: ['<i class=\'arrow arrowNext fa fa-chevron-left\'></i>', '<i class=\'arrow arrowPrev fa fa-chevron-right\'></i>'],
        responsive: {
            0: {items: 1},
            768: {items: 1},
            992: {items: 1}
        },
        items: 1,
        loop: true,
        // autoplay: true,
        dots: false,
        nav: true,
        autoplaySpeed: 10000,
        autoplayTimeout: 10000,
        smartSpeed: 1000,
    };
    apiLoaded = false;
    safeURL: any;
    video: any[] = [];

    constructor(public dialog: MatDialog, private router: Router, private apis: ApisService,
    ) {
    }

    openDonate() {
        const formDonateRed = this.dialog.open(DonateFormComponent,
            {
                width:'95%',
                maxWidth: '660px',
                maxHeight: '80vh',
            })
    }

    ngOnInit(): void {
        this.getVideos()
    }

    openDialog(): any {
        const dialogRef = this.dialog.open(RotaryFormComponent, {
            maxHeight: '67vh',
            maxWidth: '30vw',
            data: {
                animal: 'panda'
            }
        });
        dialogRef.afterClosed().subscribe(result => {
        });
    }

    navigate(): void {
        this.router.navigate(['/']).then(result => (window.location.href = 'https://newegyptgroup.com/donation-for-activity/a-donation-campaign-from-egyptians-in-the-united-states-to-support-a-haya-karima-for-our-families'));

    }

    getVideos() {
        this.apis.videosApi().subscribe(
            (data: any) => {
                this.video = data.data;
                console.log(this.video)
            });
    }

}
