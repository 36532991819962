import {Component, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Inject} from '@angular/core';
import {ApisService} from '../../services/apis.service';
import {HayahComponent} from '../../pages/hayah/hayah.component';

@Component({
  selector: 'app-confim-donation',
  templateUrl: './confim-donation.component.html',
  styleUrls: ['./confim-donation.component.scss']
})
export class ConfimDonationComponent implements OnInit {
  donationInfo: any;
  amount: any;
  name: any;
  address: any;
  dueDate: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ConfimDonationComponent>, public dialog: MatDialog) {
  }

  ngOnInit(): void {
    var object = {};
    this.data.forEach(function(value, key) {
      object[key] = value;
    });
    this.donationInfo = JSON.parse(JSON.stringify(object));
    this.amount = this.donationInfo.amount;
    this.name = this.donationInfo.fullname;
    this.dueDate = this.donationInfo.due_date;
    this.address = this.donationInfo.address;
  }

  close(): void {
    this.dialogRef.close();
  }

}
