import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {DatePipe} from '@angular/common';
import {HeaderComponent} from './components/header/header.component';
import {FooterComponent} from './components/footer/footer.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HomeComponent} from './pages/home/home.component';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {HttpClientModule} from '@angular/common/http';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {GalleryModule} from 'ng-gallery';
import {LightboxModule} from 'ng-gallery/lightbox';
import {HomeChildrenSectionComponent} from './components/home/home-children-section/home-children-section.component';
import {ChildrenComponent} from './pages/children/children.component';
import {CaseDetailsComponent} from './pages/case-details/case-details.component';
import {HomeVideosSectionComponent} from './components/home/home-videos-section/home-videos-section.component';
import {ContactUsComponent} from './pages/contact-us/contact-us.component';
import {AboutUsComponent} from './pages/about-us/about-us.component';
import {ProjectsComponent} from './pages/projects/projects.component';
import {HomeProjectsSectionComponent} from './components/home/home-projects-section/home-projects-section.component';
import {ProjectDetailsComponent} from './pages/project-details/project-details.component';
import {OurMissionComponent} from './components/home/our-mission/our-mission.component';
import {RecentNewsComponent} from './components/home/recent-news/recent-news.component';
import {OurVisionComponent} from './components/home/our-vision/our-vision.component';
import {DonateComponent} from './components/donate/donate.component';
import {VideoDescriptionComponent} from './pages/video-description/video-description.component';
import {NewsDetailsComponent} from './pages/news-details/news-details.component';
import {NewsComponent} from './pages/news/news.component';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {HayahComponent} from './pages/hayah/hayah.component';
import {OurMessageComponent} from './components/our-message/our-message.component';
import {FooterHayahComponent} from './components/footer-hayah/footer-hayah.component';
import {NotifierModule} from 'angular-notifier';
import {NotifierOptions} from 'angular-notifier';
import {CalenderComponent} from './components/calender/calender.component';
import {EventsComponent} from './pages/events/events.component';
import {TransferHttpModule, TransferHttpService} from '@gorniv/ngx-transfer-http';
import {MatTabsModule} from '@angular/material/tabs';
import {NgImageSliderModule} from 'ng-image-slider';
import {SafePipeModule} from 'safe-pipe';
import {RotaryFormComponent} from './components/rotary-form/rotary-form.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatNativeDateModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatToolbar, MatToolbarModule} from '@angular/material/toolbar';
import {DonationPartComponent} from './components/donation-part/donation-part.component';
import {NewsSlideComponent} from './components/news-slide/news-slide.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ConfimDonationComponent} from './components/confim-donation/confim-donation.component';
import {NgxPrintModule} from 'ngx-print';
import {VideosComponent} from './components/home/videos/videos.component';
import {EventComponent} from './pages/event/event.component';
import {PresidentDocumentComponent} from './components/president-document/president-document.component';
import {TestComponent} from './pages/test/test.component';
import {SafePipe} from './core/pipe/safe.pipe';
import {SharedModule} from "./shared/shared.module";
import {DonateFormComponent} from './components/donate-form/donate-form.component';
import {NgxStripeModule, StripeService} from "ngx-stripe";
import {ToastrModule} from "ngx-toastr";

const customNotifierOptions: NotifierOptions = {

    position: {
        horizontal: {
            position: 'left',
            distance: 12
        },
        vertical: {
            position: 'bottom',
            distance: 12,
            gap: 10
        }
    },
    theme: 'material',
    behaviour: {
        autoHide: 5000,
        onClick: 'hide',
        onMouseover: 'pauseAutoHide',
        showDismissButton: true,
        stacking: 4
    },
    animations: {
        enabled: true,
        show: {
            preset: 'slide',
            speed: 300,
            easing: 'ease'
        },
        hide: {
            preset: 'fade',
            speed: 300,
            easing: 'ease',
            offset: 50
        },
        shift: {
            speed: 300,
            easing: 'ease'
        },
        overlap: 150
    }

};

@NgModule({
    declarations: [
        FooterComponent,
        HomeVideosSectionComponent,
        AppComponent,
        HeaderComponent,
        HomeComponent,
        ContactUsComponent,
        AboutUsComponent,
        ProjectsComponent,
        HomeProjectsSectionComponent,
        ProjectDetailsComponent,
        HomeChildrenSectionComponent,
        ChildrenComponent,
        CaseDetailsComponent,
        OurMissionComponent,
        RecentNewsComponent,
        OurVisionComponent,
        DonateComponent,
        VideoDescriptionComponent,
        NewsDetailsComponent,
        NewsComponent,
        HayahComponent,
        OurMessageComponent,
        FooterHayahComponent,
        CalenderComponent,
        EventsComponent,
        RotaryFormComponent,
        DonationPartComponent,
        NewsSlideComponent,
        ConfimDonationComponent,
        VideosComponent,
        EventComponent,
        PresidentDocumentComponent,
        TestComponent,
        SafePipe,
        DonateFormComponent,


    ],
    imports: [
        BrowserModule.withServerTransition({appId: 'serverApp'}),
        AppRoutingModule,
        CarouselModule,
        NgxPrintModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatExpansionModule,
        MatSelectModule,
        FormsModule,
        MatCheckboxModule,
        NgxMaterialTimepickerModule,
        BrowserModule,
        AppRoutingModule,
        CarouselModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatExpansionModule,
        MatSelectModule,
        FormsModule,
        MatCheckboxModule,
        NgxMaterialTimepickerModule,
        GalleryModule,
        LightboxModule,
        NgxSkeletonLoaderModule,
        NotifierModule.withConfig(
            customNotifierOptions
        ),
        HttpClientModule,
        TransferHttpModule,
        MatTabsModule,
        NgImageSliderModule,
        SafePipeModule,
        MatDialogModule,
        ReactiveFormsModule,
        MatInputModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatAutocompleteModule,
        MatToolbarModule,
        NgxSkeletonLoaderModule.forRoot({animation: 'pulse', loadingText: 'This item is actually loading...'}),
        NgxSpinnerModule,
        SharedModule,
        ToastrModule.forRoot({
            timeOut: 100000,
            positionClass: 'toast-center-center',
            preventDuplicates: true,
            tapToDismiss: true,
            closeButton: true,

        }),
        NgxStripeModule.forRoot('pk_test_V4IgS3YdNKguXohuNj0mEU0C'),
        // pk_test_V4IgS3YdNKguXohuNj0mEU0C
        // pk_live_icmgpanW27vLYxG8o4fawX1m


    ],
    providers: [
        DatePipe
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
}
