import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ApisService} from '../../services/apis.service';
import {AppComponent} from '../../app.component';
import {SeoService} from '../../services/seo.service';
@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  videoUrl = 'https://player.vimeo.com/external/567641203.hd.mp4?s=c04076dd9a63e7d8dd2c7312cf1178db4066063b&profile_id=175';
  news: any;
  hostUrl;
  constructor( private seoService: SeoService, private router: Router, private apis: ApisService,) {
  }

  ngOnInit(): void {
    this.getNews();
    const hostUrl = AppComponent.hostUrl;
    this.hostUrl = hostUrl;
    this.seoUpdate();
  }
  seoUpdate(): any {
    this.seoService.updateConstMetaTags();
    this.seoService.updateTitle('News');
    this.seoService.updateDescription('News');
    this.seoService.updateImage(AppComponent.hostUrl + '/assets/images/haya/bg-home.jpg');
    // location.replace ('https://www.facebook.com/sharer/sharer.php?u=' + this.hostUrl + this.routerUrl);
    // window.location.href = 'https://www.facebook.com/sharer/sharer.php?u=' + this.hostUrl + this.routerUrl;
  }

  getNews(): void{
    this.apis.newsApi().subscribe((data: any) => {
      this.news = data.data;
      console.log(this.news);
    }, (err: any) => {
    });
  }
  transform(n: string): string {
    var date = new Date(n);
    var months = ['يناير', 'فبراير', 'مارس', 'إبريل', 'مايو', 'يونيو',
      'يوليو', 'أغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر'
    ];
    var days = ['اﻷحد', 'اﻷثنين', 'الثلاثاء', 'اﻷربعاء', 'الخميس', 'الجمعة', 'السبت'];
    var delDateString = days[date.getDay()] + ', ' + date.getDate() + ' ' + months[date.getMonth()] + ', ' + date.getFullYear();
    return delDateString;
  }
  newsDetails(id): void{
    this.router.navigate(['/news-details/' + id]);
  }
}
