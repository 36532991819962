<app-header></app-header>
 <div class="contact-us-page">
   <div class="help-center-header">
     <div class="container">
       <h1 class="text-center">Contact Us</h1>
     </div>
   </div>
   <div class="help-center-body">
     <!-- start contact-us -->
     <div class="container">
       <div class="contact-us">
         <form>
           <div class="inside">
             <h2>We aim to reach you anytime and anywhere</h2>
             <!-- Full name -->
             <div class="field">
               <label for="name">*Full
                 Name</label>
               <input id="name" type="text"  required/>
             </div>
             <!-- Email -->
             <div class="field">
               <label for="email">*Email Address</label>
               <input id="email" type="email"  required/>
             </div>
             <!-- mobile number -->
             <div class="field">
               <label for="mobile">*Mobile
                 Number</label>
               <input id="mobile" type="text"  required/>
             </div>
             <!-- subject -->
             <div class="field">
               <label for="subject">*Subject</label>
               <input id="subject" type="text"  required/>
             </div>
             <!-- message -->
             <div class="field">
               <label for="message">*Your Message</label>
               <textarea id="message" placeholder="Tell us anything you want to.."
                          required></textarea>
             </div>
             <div class="submit">
               <button class="button active">SEND</button>
               <!-- <i *ngIf="roundSpinner" class="fas fa-spinner fa-spin"></i> -->
             </div>
           </div>
         </form>
       </div>
     </div>
     <!-- end contact-us -->
   </div>
 </div>
<app-footer></app-footer>
