
import {Inject, Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {DOCUMENT} from '@angular/common';
import {Router} from '@angular/router';
import {AppComponent} from '../app.component';
@Injectable({
  providedIn: 'root'
})
export class SeoService {
  constructor(private title: Title, private meta: Meta, @Inject(DOCUMENT) private doc, private router: Router) { }

  updateTitle(title: string): any {
    this.title.setTitle(title);
    this.meta.updateTag({name: 'title', content: title});
    this.meta.updateTag({property: 'og:title', content: title});
    this.meta.updateTag({name: 'twitter:title', content: title});
  }

  updateDescription(desc: string): any  {
    this.meta.updateTag({name: 'description', content: desc});
    this.meta.updateTag({property: 'og:description', content: desc});
    this.meta.updateTag({name: 'twitter:description', content: desc});
  }

  updateUrl(url = AppComponent.hostUrl + this.router.url): any {
    this.meta.updateTag({property: 'og:url', content: url});
    this.meta.updateTag({name: 'twitter:url', content: url});
    this.meta.updateTag({name: 'canonical_tag', content: url});
    this.updateCanonicalURL(url);
  }

  updateImage(image = AppComponent.hostUrl + '/assets/images/blog/header.png'): any  {
    this.meta.updateTag({property: 'og:image', content: image});
    this.meta.updateTag({property: 'og:image:secure_url', content: image});
    this.meta.updateTag({name: 'twitter:image', content: image});
  }

  updateConstMetaTags(): any {
    this.meta.updateTag({property: 'og:type', content: 'website'});
    this.meta.updateTag({name: 'twitter:card', content: 'summary'});
  }

  updateCanonicalURL(url: string): any  {
    const canonicalLink = this.doc.getElementById('canonical');
    canonicalLink.setAttribute('href', url);
   }
}
