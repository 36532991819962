<div id="donation-section" class="large-sc">
  <div id="donation" class="donation">
    Donations Earned <br>
    <span class="today">Up To Date</span>
    <br>
    <span *ngIf="amount" class="price">${{numberWithCommas(amount)}}</span>
    <ngx-spinner *ngIf="!amount" class="mb-3 mt-3" [fullScreen]="false" type="ball-spin-clockwise" size="medium"></ngx-spinner>
    <article class="text-left direction-ltr important-article  text-center">All donations made by those <br> living in usa are 100% tax <br> deductible, and 100% of <br> donations go directly <br> to the project with no <br> administrative costs.</article>
  </div>

</div>
