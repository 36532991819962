<div class="container">


<section class="showcase">
    <div class="container-fluid p-0">
        <div class="row g-0">
            <div class="col-lg-8 order-lg-2 text-white showcase-img">
                <owl-carousel-o [options]="carouselOptionsVideos" class="showcase-img">
                    <ng-container>
                        <ng-container *ngFor="let item of video">
                            <ng-template carouselSlide>
                                <iframe class="showcase-img w-100"
                                        [src]="item?.video_url| safe"
                                        [title]="item.title"
                                ></iframe>
                            </ng-template>
                        </ng-container>
                    </ng-container>
                </owl-carousel-o>
            </div>
            <div class="col-lg-4 order-lg-1 my-auto showcase-text">
                <div class="content ">
                    <p>A donation campaign from Egyptians in the United States to support a "dignified life" for our
                        families
                    </p>
                    <a class="share-button mt-3 d-block">
                        <button class="share btn m-auto">
                            Share
                            <i class="fab fa-facebook"></i>
                        </button>
                    </a>
                    <a class="share-button mt-3 d-block" (click)="openDonate()">
                        <button class="share btn m-auto">
                            Donate
                        </button>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!--<div class="d-flex" *ngFor="let item of video">-->
<!--    <iframe [src]="'https://www.youtube.com/embed/'+videoUrl | safe" frameborder="0"></iframe>-->
<!--</div>-->
</div>