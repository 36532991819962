
<div class=" row">

<form [formGroup]="myForm" (ngSubmit)="createToken()">
    <div class="d-flex overflow-auto" id="style-15">
        <div class="container text-center mt-5">
            <div class="section-title" >
                Let’s get to know you.
            </div>
            <div class="">
                <div class="col-12">
                    <div class="">
                        <div class="col-12">
                            <div class="mt-5">
                                <h4 class="tell-us">Full Name</h4>
                            </div>
                            <mat-form-field appearance="fill">
                                <mat-label></mat-label>
                                <input matInput placeholder="Full Name" type="text" formControlName="fullname" >
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <div class="">
                                <h4 class="tell-us">Phone Number</h4>
                            </div>
                            <mat-form-field appearance="fill">
                                <mat-label></mat-label>
                                <input matInput placeholder="phone number" type="text" formControlName="phone">
                            </mat-form-field>
                        </div>
                        <div class="col-12" >
                            <div class="mt-0">
                                <h4 class="tell-us">Email</h4>
                            </div>
                            <mat-form-field appearance="fill">
                                <mat-label></mat-label>
                                <input matInput placeholder="Email" type="text" formControlName="email" >
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="ml-2 row">
                        <div class="col-12 section-title ml-2"  >
                           Payment details
                        </div>
              <!--                      <div class="col-12">
                                      <div class="mt-5">
                                        <h4 class="tell-us">Name on Credit Card </h4>
                                      </div>
                                      <mat-form-field appearance="fill">
                                        <mat-label></mat-label>
                                        <input matInput placeholder="" type="text">
                                      </mat-form-field>
                                    </div>-->
                        <div class="col-12">
                            <div class="mt-5">
                                <h4 class="tell-us"  >Card information</h4>
                            </div>
                            <mat-form-field appearance="fill">
                                <mat-label></mat-label>
                                <input matInput hidden placeholder="" type="text">
                                <ngx-stripe-card
                                        [options]="cardOptions"
                                ></ngx-stripe-card>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row ml-3">
                        <div class="col-12 section-title"  >
                         DONATE
                            <div class="row justify-content-center">
                                <div class="col-12 section"  >
                                    Custom amount
                                </div>
                                <div class="col-12 ">
                                    <div class="input-group">
                                        <span class="input-group-text" style="z-index: 66">$</span>
                                        <input id="custom_amount" class="position-absolute" type="number"
                                               formControlName="amount"
                                               style="width: 100%; min-height: 100%;padding-left: 38px">
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col-4" *ngFor="let donate of donateSelect">
                            <button class="pill" [class]="select == donate ? 'pill-select':''"
                                    (click)="selectDonate(donate)" type="button">${{donate}}</button>
                        </div>
                    </div>
                    <div class="row mt-4 justify-content-center" style="margin-bottom: 30px; padding-left: 25px">
                        <button class="btn submit-btn" [disabled]="disabled" type="submit"  >Donate</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</form>
</div>