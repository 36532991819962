<div class="announcement-section">
  <div class="ticker-wrap" *ngIf="donorsNames.length >0">
    <div class="ticker">
      <ng-container *ngFor="let item of donorsNames">
        <div class="ticker__item">
          <div class="d-flex">
            <div class="card-title-news">
              <span>{{item['name']}}</span>
            </div>
            <ng-container>
              <div class="importantTag">
                <span>Special Thanks To</span>
                <img width="50" src="assets/images/haya/logo-haya.png">
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <ng-container *ngIf="donorsNames.length == 0">
    <ngx-skeleton-loader animation="progress" count="1" appearance="line"></ngx-skeleton-loader>
    <!-- above line will produce the rendering of 5 circles with the pulse animation and the aria-valuetext attribute set with "This item is actually loading..." -->
  </ng-container>
</div>
