
  <div class="container ">

      <h1>  NEWS      </h1>

      <owl-carousel-o [options]="carouselOptions2" class="row gx-4 justify-content-center">
        <ng-container>
          <ng-template carouselSlide *ngFor="let news of news">
            <div class="col-12 mt-4" (click)="newsDetails(news.id)">
              <div   class="card text-center h-100">

                  <div  >


              <div class="">
                <img style="height: 177px"  src="{{news.images[0].image}}">
                <div class="project-details">
                  <p class="project-name">{{news.title.slice(0,30)}}....</p>
                  <p class="article" >{{news.article_string.slice(0,200)}}</p>
                  <hr class="hr">
                  <div class="d-flex justify-content-end">
                    <div class="text-right d-flex flex-row"><img class="mr-1"  src="../../../../assets/images/Time%20Icon.svg">{{transform(news.date)}}</div>
                  </div>
                </div>
              </div>

                  </div>

                  </div>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>

    </div>