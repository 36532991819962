<section>
  <div class="container">
    <div class="row" id="print-section">
      <header class="text-center">
        <img alt="" class="width-fill mt-4 mb-4" src="assets/images/haya/rotary.jpg">
        <p class="thank">Thank You </p>
        <p class="name">{{name}}</p>
      </header>
      <ng-container>
        <div class="col-12 text-center donation">
          <span>Your Donation Amount </span>
          <span>{{amount}}</span>
        </div>
        <div class="col-12 text-center">
          <span>Payment date </span>
          <span>{{dueDate}}</span>
        </div>
        <div class="col-12 text-center address p-0">
          <p>Please Mail your Check To Maple Valley Rotary</p>
          P.O Box 1102
          <br>
          Maple Valley.WA 98038
        </div>
      </ng-container>
      <article class="text-left direction-ltr important-article mt-4">
        All donations made by those living in usa are
        100% tax deductible, and 100% of donations go directly to the project with no administrative costs.
      </article>
    </div>
    <div class="d-flex justify-content-center mt-5">
      <button class="close mr-3" (click)="close()">Close</button>
      <p class="d-flex print align-items-center justify-content-center ">

        <button printSectionId="print-section" [useExistingCss]="true"
                styleSheetFile="src/app/components/confirm-donation/confim-donation.component.scss" class=""
                ngxPrint>print
        </button>
        <img alt="" src="https://www.egypt-nyc.com/assets/images/printer.png">
      </p>
    </div>
  </div>
</section>
