<nav class=" navbar-expand-lg navbar-light white-nav  mobileFixPadding">
  <div class="">
    <div class="" id="navbarText">
      <div class="">
<!--        <div class="header-content d-flex justify-content-end flex-row">-->
<!--          <div class="lang d-flex">-->
<!--            <i class="fas fa-globe"></i>-->
<!--            <span>EN</span>-->
<!--          </div>-->
<!--          <div class="line"></div>-->
<!--          <span>تسجيل الدخول</span>-->
<!--        </div>-->
          <div class="nav-item logo">
            <a class=" nav-link-image mt-5" routerLink="/">
              <img alt="" src="./assets/images/haya/HAYA%20KARIMA%20FOUNDATION.png">
            </a>
          </div>
        <ul class="navbar-nav pr-0">
          <li class="nav-item active">
            <a class="nav-link" routerLinkActive="underline-decoration"
               [routerLinkActiveOptions]="{exact:true}" routerLink="/">Home <span class="sr-only">(current)</span></a>
          </li>
<!--          <li class="nav-item">-->
<!--            <a class="nav-link" routerLinkActive="underline-decoration" routerLink="/Programs">البرامج</a>-->
<!--          </li>-->
<!--          <li class="nav-item">-->
<!--            <a class="nav-link" routerLinkActive="underline-decoration" routerLink="/news">الأخبار</a>-->
<!--          </li>-->
<!--          <li class="nav-item">-->
<!--            <a class="nav-link" routerLinkActive="underline-decoration" routerLink="/pcr-test-for-travel">شارك معنا</a>-->
<!--          </li>-->
<!--          <li class="nav-item">-->
<!--            <a class="nav-link" routerLinkActive="underline-decoration" routerLink="/pcr-test-for-travel">تبرع</a>-->
<!--          </li>-->
<!--          <li class="nav-item">-->
<!--            <a class="nav-link" routerLinkActive="underline-decoration" routerLink="/pcr-test-for-travel">مشروعنا</a>-->
<!--          </li>-->
<!--          <li class="nav-item">-->
<!--            <a class="nav-link" routerLinkActive="underline-decoration" routerLink="/about">من نحن</a>-->
<!--          </li>-->
<!--          <li class="nav-item">-->
<!--            <a class="nav-link" routerLinkActive="underline-decoration" routerLink="/contact-us">اتصل بنا</a>-->
<!--          </li>-->
<!--          <li class="nav-item search-button d-flex align-items-center">-->
<!--            <i class="fa fa-search" aria-hidden="true"></i>-->
<!--            <a class="nav-link" routerLink="/pcr-test-for-travel">بحث</a>-->
<!--          </li>-->
        </ul>
      </div>
    </div>
  </div>
</nav>
