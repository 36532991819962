import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {DonateFormComponent} from "../../components/donate-form/donate-form.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-donation-btn',
  templateUrl: './donation-btn.component.html',
  styleUrls: ['./donation-btn.component.scss']
})
export class DonationBtnComponent implements OnInit {

  constructor(private router:Router,
              private DonateForm:MatDialog) { }

  ngOnInit(): void {
  }
  openDinate(){
    const formDonateRed = this.DonateForm.open(DonateFormComponent,
        {maxWidth:'660px',
          maxHeight:'828px'})
  }
/*  openDonate(): void{
    this.router.navigate(['/']).then(result => (window.location.href = 'https://newegyptgroup.com/donation-for-activity/a-donation-campaign-from-egyptians-in-the-united-states-to-support-a-haya-karima-for-our-families'));
// let url="www.google.com";
// var donateWindow=window.open(
//     'https://m.newegyptgroup.com/donation-for-activity/a-donation-campaign-from-egyptians-in-the-united-states-to-support-a-haya-karima-for-our-families' ,
//     'donate-popup',
//     'width=390px,height=844px'
// )
  }*/
}
