<div class="container w-39  mb-4rem dir">
  <h1 class="header"> Initiative Objectives</h1>
  <div class="mission-section row">
    <div class="col-md-6 col-sm-12 p-4">
      <p class="article">
        Alleviating the burden of citizens in communities most in need in the countryside and slums in urban areas.</p>
      <!--        <div class="d-flex justify-content-center">-->
      <!--      <button class="projects-btn">معرفة المزيد</button>-->
      <!--        </div>-->
      <article class="text-left direction-ltr important-article mt-4">All donations made by those living in usa are 100% tax deductible, and 100% of donations go directly to the project with no administrative costs.</article>
    </div>
   <!-- <div class="col-md-6 col-sm-12 height-div">
      <video class="home-video"
             title="EGYPT"
             autoplay="" muted="" playsinline="" loop="" onloadedmetadata="this.muted = true"
             (contextmenu)="prevent($event)"
             oncanplay="this.play()"
             src="{{videoUrl3}}">
      </video>
    </div>-->
  </div>
</div>
