import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DonationBtnComponent} from "./donation-btn/donation-btn.component";
import { DonatePriceComponent } from './donate-price/donate-price.component';



@NgModule({
  declarations: [

      DonationBtnComponent,
       DonatePriceComponent,
  ],
  imports: [
    CommonModule
  ],
    exports: [
        DonationBtnComponent,
        DonatePriceComponent,
    ]
})
export class SharedModule { }
