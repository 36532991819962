import {Component, HostListener, OnInit, Output, EventEmitter, Input} from '@angular/core';
import {ApisService} from '../../services/apis.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-donation-part',
  templateUrl: './donation-part.component.html',
  styleUrls: ['./donation-part.component.scss']
})
export class DonationPartComponent implements OnInit {
  totalDonation: any;
  @Input() amount: any;

  @HostListener('window:scroll', ['$event']) // for window scroll events
  scroll(): any {
    const element = document.getElementById('donation-section');
    const element2 = document.getElementById('home_video_section');
    if (window.scrollY >= ((element?.offsetHeight + element?.offsetTop)) - 750) {
      document.getElementById('donation-section')?.classList.remove('large-sc');
      document.getElementById('donation-section')?.classList.add('small-sc');
    } else if (window.scrollY < (element2.offsetHeight + element2.offsetTop)) {
      document.getElementById('donation-section')?.classList.add('large-sc');
      document.getElementById('donation-section')?.classList.remove('small-sc');
    }
  }

  constructor(private apis: ApisService, private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {

    this.spinner.show();
    this.donation();
    window.addEventListener('scroll', this.scroll, true);

  }

  numberWithCommas(x): any {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  donation(): any {
    this.apis.donation().subscribe((response: any) => {
    }, err => {
      // console.log(err);
    });
  }

}
