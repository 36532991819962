<app-header></app-header>
<img style="width: 100%" src="assets/images/gray-plane-wing-62623.png">
<h1 class="title">New Egypt Projects</h1>
<div class="projects">
  <div class="container">
    <div class="project-cards">
      <div class="project" *ngFor="let project of projects" (click)="projectDetails(project.id)">
        <div *ngIf="project.project_media[0].url_type == 0" class="head-img">{{project.budget | currency}}</div>
        <video *ngIf="project.project_media[0].url_type == 1" controls src="{{project.project_media[0].url}}"></video>
        <img style="height: 177px" *ngIf="project.project_media[0].url_type == 0" src="{{project.project_media[0].url}}">
        <div class="project-details">
          <p class="project-name">{{project.name_ar}}</p>
          <div class="location"><img src="../../../../assets/images/Icon%20feather-map-pin.svg"> الغربية - صفط تراب</div>
          <span class="article">{{project.description_ar.substring(0,200)}}</span>
          <hr class="hr">
          <div class="d-flex justify-content-between" style="direction: rtl">
            <div class="duration"><img class="ml-1"  src="../../../../assets/images/Time%20Icon.svg"> تاريخ البدء :</div>
            <div class="text-right">{{transform(project.start_date)}}</div>
          </div>
          <div class="d-flex justify-content-between mb-2" style="direction: rtl">
            <div class="duration"><img class="ml-1"  src="../../../../assets/images/Time%20Icon.svg"> تاريخ الانتهاء :</div>
            <div class="text-right">{{transform(project.end_date)}}</div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
<app-footer></app-footer>
