import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {of, Subscription, timer} from 'rxjs';
import {catchError, filter, switchMap} from 'rxjs/operators';
import {ApisService} from '../../services/apis.service';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit, OnDestroy {
  minutes: number;
  subscription: Subscription;
  intervalPeriod: number = 1;
  data: any;

  constructor(private http: HttpClient, private apis: ApisService) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


}
