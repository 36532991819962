<app-header></app-header>
<img style="width: 100%" src="assets/images/gray-plane-wing-62623.png">
<h1 class="title">أهم الأخبار</h1>
<div class="projects">
  <div class="container">
    <div class="project-cards">
      <div class="project" *ngFor="let news of news" (click)="newsDetails(news.id)">
        <video *ngIf="news.newsMedia[0].url_type == 1" src="{{news.newsMedia[0].url}}" controls></video>
        <img style="height: 177px" *ngIf="news.newsMedia[0].url_type == 0" src="{{news.newsMedia[0].url}}">
        <div class="project-details">
          <p class="project-name">{{news.title_ar.substring(0,50)}}......</p>
          <p class="article">{{news.article_ar.substring(0,200)}}......</p>
          <hr class="hr">
          <div class="d-flex justify-content-between">
            <div class="duration"><img class="ml-1"  src="../../../../assets/images/Time%20Icon.svg"> تاريخ الخبر :</div>
            <div class="text-right">{{transform(news.date)}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
