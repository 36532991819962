<div class="container mb-4rem">
  <h1 class="header"> رسالتنا</h1>
    <div class="mission-section row">
      <div class="col-md-6 col-sm-12 p-4">
        <div class="title">رسالتنا</div>
        <p class="article">مجموعة نيو إيجيبت
          و هي مجموعة مصرية وطنية خالصة غير هادفة للربح تبني جسرا بين أبناء الجالية المصرية بالولايات المتحدة الأمريكية و بلدهم الحبيب مصر
          و تقوم مجموعة نيو إيجيبت بالعديد من الأنشطة و المجهودات في مجالات عديده منها التعليم و التنمية و تمكين المرأة لبناء مستقبل مشرق
          مستغلة في ذلك طاقات و خبرات خيرة أبناء مصر داخل و خارج بلدنا الحبيب مصر.
          كما أنها مجموعة فاعله لدعم بعض قري محافظات الصعيد و محافظات منطقة الدلتا, فقد كانت زيارة إحدى القرى بمحافظة قنا بالتعاون مع بنك الكساء
          لدعم أخواتنا و أهلنا من الغير قادرين عن مواجهة صعوبات الحياه عام 2014 بداية لسلسة من الدعم لبعض القرى للمساهمة في عملية التنمية  .
         </p>
<!--        <div class="d-flex justify-content-center">-->
<!--      <button class="projects-btn">معرفة المزيد</button>-->
<!--        </div>-->
      </div>
      <div class="col-md-6 col-sm-12">
        <video class="home-video"
               title="EGYPT"
               autoplay="" muted="" playsinline="" loop="" onloadedmetadata="this.muted = true"
                (contextmenu)="prevent($event)"
               oncanplay="this.play()"
               src="{{videoUrl3}}">
        </video>
      </div>
    </div>
</div>
