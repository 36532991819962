<div class="footer">
  <div class="footer-body">
    <div class="container">
      <div class="row container-text">
        <div class="col-md-6 col-sm-12 order-2">
          <div class="logo">
            <a [routerLink]="['/']"><img title="flyallover" src="./assets/images/logo.png" class="img-fluid"
                                         width="244" alt="NEW EGYPT logo"></a>
          </div>
        </div>
        <div class="col-md-6 col-sm-12 item text justify-content-sm-center">
          <h6>من نحن</h6>
          <p >مجموعة نيو إيجيبت و هي مجموعة مصريةوطنية خالصة غير هادفة للربح تبني جسرا بين أبناء الجالية المصرية
            بالولايات المتحدة الأمريكية و بلدهم الحبيب مصر و تقوم مجموعة نيو إيجيبت بالعديد من الأنشطة و المجهودات في
            مجالات عديده منها التعليم و التنمية و تمكين المرأة لبناء مستقبل مشرق مستغلة في ذلك طاقات و خبرات خيرة أبناء
            مصر داخل و خارج بلدنا الحبيب مصر. كما أنها مجموعة فاعله لدعم بعض قري محافظات الصعيد و محافظات منطقة الدلتا,
            فقد كانت زيارة إحدى القرى بمحافظة قنا بالتعاون مع بنك الكساء لدعم أخواتنا و أهلنا من الغير قادرين عن مواجهة
            صعوبات الحياه عام 2014 بداية لسلسة من الدعم لبعض القرى للمساهمة في عملية التنمية . ففي كل عام تشارك المجموعة
            في احتفالات يوم اليتيم بإحدى قرى محافظة قنا و تساهم في رسم البسمة على وجوه الأطفال اليتامى و تقوم بدعمهم و
            مساندتهم و في مجال تنشيط و دعم السياحة المصرية كانت حملات " زورونا " منذ العام 2015 و التي كانت تقام في
            ولاية نيويورك. مجموعة نيوايجيبت تستعد حاليا لتدشين أول مكتب اقليمي في مصر لتكون جسر للتواصل بينا وبين الوطن
            الحبيب مصر .</p>
        </div>
        <!--        <div class="col-3 text-left d-none">-->
        <!--          <div class="item text ">-->
        <!--            <h6>WHAT WE DO</h6>-->
        <!--            <p>Sing for Hope uplifts lives by unleashing creativity virtually and in-person for under-resourced schools,-->
        <!--              healthcare facilities, refugee camps, transit hubs, and public spaces. Our work ranges from arts workshops-->
        <!--              that improve academics for at-risk students, to specialized music programs that enhance cognition for-->
        <!--              dementia patients, to arts interventions that facilitate integration for refugee youth, to the Sing for-->
        <!--              Hope Pianos that unite communities in our world’s public spaces.-->
        <!--            </p>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <div class="col-3 text-left d-none container-follow">-->
        <!--          <div class="item follow">-->
        <!--            <h6>تابعنا على</h6>-->
        <!--            <ul>-->
        <!--              <li><a target="_blank" rel="nofollow" href=""><i-->
        <!--                class="fab fa-facebook-f"></i></a></li>-->
        <!--              <li><a target="_blank" rel="nofollow" href=""><i-->
        <!--                class="fab fa-twitter"></i></a></li>-->
        <!--              <li><a target="_blank"-->
        <!--                     href=""><i-->
        <!--                class="fab fa-youtube"></i></a></li>-->
        <!--              <li><a target="_blank" rel="nofollow" href=""><i-->
        <!--                class="fab fa-instagram"></i></a></li>-->
        <!--              <li><a target="_blank" rel="nofollow" href=""><i-->
        <!--                class="fab fa-linkedin-in"></i></a></li>-->
        <!--            </ul>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
  <div class="footer-copyright">
    <div class="container padding-17">
      <div class="row">
        <div class="col-12 text-left">
          <p><a [routerLink]="['/']"> كل الحقوق محفوظة – </a>نيو إيجبت </p>
        </div>
        <!--        <div class="col-6">-->
        <!--          <ul>-->
        <!--            <li><a [routerLink]="['/privacy']">الشروط والأحكام</a></li>-->
        <!--            <li><a [routerLink]="['/disclaimer']">إخلاء مسؤولية عام</a></li>-->
        <!--          </ul>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</div>
