import {Component, Inject, Input, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApisService} from "../../services/apis.service";
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import {StripeCardElementOptions, } from '@stripe/stripe-js';
import {ToastrService} from "ngx-toastr";
import {isPlatformBrowser} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {MatAutocomplete} from "@angular/material/autocomplete";

@Component({
  selector: 'app-donate-form',
  templateUrl: './donate-form.component.html',
  styleUrls: ['./donate-form.component.scss']
})
export class DonateFormComponent implements OnInit {
  activity: any;
  videos:any = [];
  slug: any;

  @Input() total;
  disabled: boolean = false;
  stripeCardValid: boolean = false;
  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };
  // elementsOptions: StripeElementsOptions = {
  //   locale: 'en'
  // };
  myForm: FormGroup;
  user:any;
  token: any;
  generalpurposes: any;
  donateactivities: any;
  donateforactivity: any;
  show: boolean = true;
  hidden: boolean = true;
  activities: any;
  tasksId: any;
  // activities: MatAutocomplete;
  purpose: MatAutocomplete;
  donateSelect: any[] =[100,500,2000,4000,8000,10000];
  select:number;
  errorMessage!: string;
  copied:boolean=false;
  activitiesId:number;
  tesksId:string;
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
              private apis: ApisService,
              private fb: FormBuilder,
              private stripeService: StripeService,
              private toastr: ToastrService,

              private activatedRoute : ActivatedRoute,
  ) {

  }

  ngOnInit(): void {

    this.myForm = this.fb.group({
      fullname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      // card_number: ['', Validators.required],
      // exp_month: ['', Validators.required],
      // exp_year: ['', Validators.required],
      // cvc: ['', Validators.required],
      amount: ['', Validators.required],
      stripeToken: ['', Validators.required],

    });
  }
  createToken(): void {
    const stripeToken = this.myForm.get('stripeToken').value;
    this.stripeService
        .createToken(this.card.element, { name: stripeToken })
        .subscribe((result) => {
          if (result.token) {
            this.myForm.patchValue({
              stripeToken: result.token.id
            })
            this.onSubmit()
            // this.onSubmit(this.myForm);
            // this.formSubmited = true;
            // Use the token
            console.log(result.token.id);
          } else if (result.error) {
            // Error creating the token
            console.log(result.error.message);
          }
        });
  }

  selectDonate(select:number){
    this.select = select
    this.myForm.patchValue({
      amount: select
    })
  }
  onSubmit() {
    this.disabled = true;

    if (this.user){
      this.myForm.controls['fullname'].disable();
      this.myForm.controls['email'].disable();
      this.myForm.controls['phone'].disable();
    }
    this.tasksId=9;
    this.apis.getDonateForActivity(this.tasksId,this.myForm.value).subscribe({
      next:(res:any)=>{
        console.log('donation =>', res);
        this.toastr.success(res.message, 'Success');
        this.disabled = false;

      },error:(err:any)=>{
        console.log(err)
        this.errorMessage = err.error.errors
        this.toastr.error(err.error.message, 'Failed');
        this.disabled = false;
      }
    })
    console.log(this.myForm.value)
  }

  donateForActivityApi(): any {
    this.apis.getDonateForActivity(this.tasksId,this.myForm.value).subscribe((data: any) => {
          this.donateforactivity = data;
          console.log(this.donateforactivity)
        },
        err => {
          console.log(err);
        });
  }
}